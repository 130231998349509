<template>
    <div>
        <h1 class="title is-4">
            <template v-if="$route.params.id">{{ $t("Interface.IronLogic.Edit.Title") }}</template>
            <template v-else>{{ $t("Interface.IronLogic.Add.Title") }}</template>
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <div class="columns is-multiline">
                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.IronLogic.Name')"
                >
                    <b-field
                        :label="$t('Interface.IronLogic.Name')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Name" type="text" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required|numeric|max_value:2147483647"
                    class="column is-half"
                    :name="$t('Interface.IronLogic.Serial')"
                >
                    <b-field
                        :label="$t('Interface.IronLogic.Serial')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input
                            v-model.number="Form.Serial"
                            type="number"
                        />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.IronLogic.Login')"
                >
                    <b-field
                        :label="$t('Interface.IronLogic.Login')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Login" type="text" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-half"
                    :name="$t('Interface.IronLogic.Password')"
                >
                    <b-field
                        :label="$t('Interface.IronLogic.Password')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Password" type="password" />
                    </b-field>
                </ValidationProvider>

                <ValidationProvider
                    v-slot="{ valid, errors }"
                    tag="div"
                    rules="required"
                    class="column is-full"
                    :name="$t('Interface.Door.Address')"
                >
                    <b-field
                        :label="$t('Interface.Door.Address')"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors[0]"
                    >
                        <b-input v-model="Form.Address" type="text" />
                    </b-field>
                </ValidationProvider>
            </div>

            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid"
                @click.prevent="handleSubmit(submit)"
            >
                <template v-if="$route.params.id">
                    {{ $t("Interface.Button.Edit") }}
                </template>
                <template v-else>
                    {{ $t("Interface.Button.Add") }}
                </template>
            </button>
        </ValidationObserver>
        <b-loading
            :is-half-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    IRONLOGIC_CONTROLLER_LIST_REQUEST,
    IRONLOGIC_CONTROLLER_GET_REQUEST,
    IRONLOGIC_CONTROLLER_ADD_REQUEST,
    IRONLOGIC_CONTROLLER_EDIT_REQUEST
} from "@/store/actions/ironlogic"

export default {
    name: "IronLogicAdd",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                Serial: null,
                Name: null,
                Address: null,
                Login: null,
                Password: null
            }
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            this.switchLoading()
            this.$store.dispatch(IRONLOGIC_CONTROLLER_GET_REQUEST, this.$route.params.id)
                .then((response) => this.getData(response.data))
                .finally(() => this.switchLoading())
        }
    },
    methods: {
        getData(data) {
            this.Form.Serial = data.serial
            this.Form.Name = data.name
            this.Form.Address = data.address
            this.Form.Login = data.login
            this.Form.Password = data.password
        },
        submit() {
            this.switchLoading()
            if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
                this.$store.dispatch(IRONLOGIC_CONTROLLER_EDIT_REQUEST, { Form: this.Form, Id: this.$route.params.id })
                    .then(() => {
                        this.$store.dispatch(IRONLOGIC_CONTROLLER_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "IronLogic-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
            else {
                this.$store.dispatch(IRONLOGIC_CONTROLLER_ADD_REQUEST, this.Form)
                    .then(() => {
                        this.$store.dispatch(IRONLOGIC_CONTROLLER_LIST_REQUEST)
                            .then(() => this.$router.push({ name: "IronLogic-List" }))
                    })
                    .finally(() => this.switchLoading())
            }
        }
    },
    metaInfo() {
        return {
            title: (this.$route.params.id) ? this.$t("Interface.IronLogic.Edit.Title") : this.$t("Interface.IronLogic.Add.Title")
        }
    }
}
</script>
